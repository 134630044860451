import React from 'react';
import {useRouteMatch} from 'react-router-dom';
import Home from './home'
import About from './about'
import Recruit from './recruit'
import Product from './product'
import Foot from './foot'

const Main = props=>
{
  
    const { path, url, params } = useRouteMatch()
    console.log(path,url,params)
    const [activePage,setActivePage] = React.useState(params.page || "home")
    return (<div style={{background:'white'}}>
        <div style={{background:'white',height:20}}></div>
                <div style={{height:60}}><img src={require('../img/logo.png')} alt="" style={{height:38,marginLeft:'16%'}}></img></div>
                <div style={{width:'100%',height:56,background:'rgb(0,66,106)',flexDirection:'row',display:'flex',alignItems:'center'}}>
                <div onClick={()=>{setActivePage('home')}} to={`${path}/home`} style={{display:'flex',fontSize:20,backgroundColor: activePage==='home' ?'white':'rgb(0,66,106)',width:100,marginLeft:"16%",textDecoration:"none",height:56,justifyContent:'center',alignItems:'center'}}>
                  <div style={{color:activePage==='home' ?'rgb(0,66,106)':'white'}}>首页</div></div>
                {/* <div onClick={()=>{setActivePage('product')}}  to="/product" replace="true" style={{display:'flex',fontSize:20,backgroundColor:activePage==='product' ?'white':'#f0c032',width:100,textDecoration:'none' ,height:56,justifyContent:'center',alignItems:'center'
              }}><div style={{color:activePage==='product' ?'#f0c032':'white'}}>产品中心</div>
              </div> */}
                <div onClick={()=>{setActivePage('recruit')}} to='/recruit' replace="true" style={{display:'flex',fontSize:20,backgroundColor:activePage==='recruit' ?'white':'rgb(0,66,106)',width:100,textDecoration:'none',height:56,justifyContent:'center',alignItems:'center'}}>
                  <div style={{color:activePage==='recruit' ?'rgb(0,66,106)':'white'}}>在线招聘</div></div>
                  <div onClick={()=>{setActivePage('about')}}  to='/about'   replace="true" style={{display:'flex',fontSize:20,backgroundColor:activePage==='about' ?'white':'rgb(0,66,106)',width:100,textDecoration:'none',height:56,justifyContent:'center',alignItems:'center'}}>
                    <div style={{color:activePage==='about' ?'rgb(0,66,106)':'white'}}>联系我们</div>
                  </div>
                  <div onClick={()=>{setActivePage('download')}}  to='/download'   replace="true" style={{display:'flex',fontSize:20,backgroundColor:activePage==='download' ?'white':'rgb(0,66,106)',width:100,textDecoration:'none',height:56,justifyContent:'center',alignItems:'center'}}>
                    <div style={{color:activePage==='download' ?'rgb(0,66,106)':'white'}}>下载</div>
                  </div>
                </div>
                
                    {
                    activePage==='home' ? <Home></Home>:null
                    }
                    {
                    activePage==='product' ? <Product onDownload={()=>{
                      setActivePage('download')
                    }}></Product>:null
                    }
                    {
                    activePage==='recruit' ? <Recruit></Recruit>:null
                    }

                    {
                    activePage==='about' ? <About></About>:null
                    }
                    {
                      activePage ==='download' ?<Product/>:null
                    }
                  <Foot/>
            </div>
            )
  
}
export default Main