import React from 'react';
import 'element-theme-default';
import  {Layout} from 'element-react';

export default class Home extends React.Component
{
  render()
  {
    return (<div>
              <div style={{width:'100%',textAlign:'center'}}><img src={require('../img/banner.png')} alt="" style={{width:'100%'}}></img></div>
              <div style={{width:'80%',margin:'0 auto'}}>
              <div style={{textAlign:'center',height:120,marginTop:60}}><img src={require('../img/base_title.png')} alt="" style={{height:66,width:540}}></img></div>
                <Layout.Row>
                <Layout.Col span="12"><p style={{height:120,margin:40,color:'gray'}}>《钓鱼基地》是一款提供找钓点服务的APP。我们通过地图搜索，关键词匹配为客户快速定位到附近的钓点。</p></Layout.Col>
                <Layout.Col span="12"> <div style={{height:314,width:540,textAlign:'center'}}><img src={require('../img/base.png')} alt="" style={{height:314,width:540}}></img></div></Layout.Col>
                </Layout.Row>
                <div style={{textAlign:'center',height:120,marginTop:60}}><img src={require('../img/core_title.png')} alt="" style={{height:66,width:407}}></img></div>
                <Layout.Row>
                <Layout.Col span="12"> <div style={{height:314,width:540,textAlign:'center'}}><img src={require('../img/core.png')} alt="" style={{height:314,width:540}}></img></div></Layout.Col>
                <Layout.Col span="12"><p style={{height:120,margin:40,color:'gray'}}>通过地图，为客户直观定位到附近的钓点，规划合理路线，为客户提供方便。</p></Layout.Col>
                </Layout.Row>
                <div style={{textAlign:'center',height:120,marginTop:60}}><img src={require('../img/service_title.png')} alt="" style={{height:66,width:475}}></img></div>
                <Layout.Row>
                <Layout.Col span="12"><p style={{height:120,margin:40,color:'gray'}}>客户使用服务，并可对每个钓点进行评价和关键词关联，为后续的客户提供参考。</p></Layout.Col>
                <Layout.Col span="12"> <div style={{height:314,width:540,textAlign:'center'}}><img src={require('../img/service.png')} alt="" style={{height:314,width:540}}></img></div></Layout.Col>
                </Layout.Row>
                <div style={{textAlign:'center',height:120,marginTop:60}}><img src={require('../img/furture_title.png')} alt="" style={{height:66,width:196}}></img></div>
                <Layout.Row>
                <Layout.Col span="12"> <div style={{height:314,width:540,textAlign:'center'}}><img src={require('../img/furture.png')} alt="" style={{height:314,width:540}}></img></div></Layout.Col>
                <Layout.Col span="12"><p style={{height:120,margin:40,color:'gray'}}>通过客户的评价和建议完善服务。</p></Layout.Col>
                </Layout.Row>
            </div>
            <div style={{height:60}}></div>
          </div>
        )
  }
}