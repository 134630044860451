import React from 'react';

export default class About extends React.Component
{
  componentDidMount()
  {

  }
  render()
  {
    return (<div>
          <div style={{width:'100%',textAlign:'center'}}><img src={require('../img/banner_4.png')} alt="" style={{width:'100%'}}></img></div>
            <div style={{width:'80%',margin:'0 auto'}}>
      
              <div style={{textAlign:'center',height:120,marginTop:60}}><img src={require('../img/email_title.png')} alt="" style={{height:66,width:479}}></img></div>
              
              <p style={{textAlign:'center',margin:'0 auto'}}>email:hr@tcctop.com </p>
              <div style={{height:60}}></div>
            </div>
            <div style={{backgroundColor:'#f7f7f7'}}>
            <div style={{backgroundColor:'#f7f7f7',height:60}}></div>
              <div style={{textAlign:'center',height:120}}><img src={require('../img/address_title.png')} alt="" style={{height:66,width:619}}></img></div>
              <p style={{textAlign:'center'}}>武汉东湖高新现代世贸中心c座2003</p>
              <div style={{textAlign:'center',marginTop:60}}><img src={require('../img/location.png')} alt=""></img></div>
              </div>
              <div style={{backgroundColor:'#f7f7f7',height:60}}></div>
        </div>)
  }
}