import { WingBlank ,WhiteSpace, View,Text,Flex,Toast} from 'antd-mobile';
import React from 'react';
import 'antd-mobile/dist/antd-mobile.css';
import imgLogo from "../img/logo.png"
import imgBorder from "../img/border.png"
import imgCode from "../img/code.png"
import imgLogo192 from "../img/logo192.png"
import axios from 'axios'
import "./index.css"
import copy from 'copy-to-clipboard'
import {useParams} from 'react-router'
import Urls from './config.js'
const mianColor = "#00426a"
const isWeiXin=()=>
{

  let ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i)?.indexOf("micromessenger")>-1 || ua.match(/WeiBo/i)?.indexOf('weibo')>-1) {
    return true;
  } else if (ua.indexOf('mobile mqqbrowser') > -1 || ua.indexOf('webp') > -1) {
    return true;
  } else if (ua.indexOf('iphone') > -1 || ua.indexOf('mac') > -1) {
    if (ua.indexOf('qq') > -1) {
      return true;
    }
  }
  return false;
}
const getUrl = (_url,cb)=>
{

 
  axios.get(_url).then(success=>{
 
    cb&&cb(success)
  },err=>{
    console.log(err)
  })
}
const Download =()=>{
 
        const [customerUrl,setCustomerUrl] = React.useState('')
        const [iosUrl,setIosUrl] =React.useState('')
        const [officalUrl,setOfficalUrl] =React.useState('')
        const [googleUrl,setGoogleUrl] =React.useState('')
        React.useEffect(()=>{
            getUrl(Urls.customer,res=>{
               setCustomerUrl(res.data)
            })
            getUrl(Urls.google,res=>{
              setGoogleUrl(res.data)
           })
           getUrl(Urls.offical,res=>{
            setOfficalUrl(res.data)
         })
         getUrl(Urls.ios,res=>{
          setIosUrl(res.data)
       })
        },[])
        const [isShow] = React.useState(isWeiXin())
        return (
          <View style={{flex:1,justifyContent:'center'}}>
            {
            isShow?
            <View style={{flex:1,display:'flex',flexDirection:'column'}}>
                  <View style={{position:'absolute',right:0}}>
                    <View className='App-arrow'  style={{
                        display: 'block',
                        position: 'absolute',
                        right:18,
                        top: 5,
                        width:12,
                        height:12,
                        backgroundColor:mianColor,
                    }}></View>
                    <View style={{display:'flex',marginTop:10,flexDirection:'column',alignItems:'center',justifyContent:'center',backgroundColor:mianColor,flexWrap:'wrap',padding:8,borderRadius:16}}>
                      <View style={{justifyContent:'center',alignItems:'center',flexWrap:'wrap'}}>
                      <Text style={{color:'white'}}>请点击右上角</Text>
                      </View>
                      <View style={{justifyContent:'center',alignItems:'center',flexWrap:'wrap'}}>
                      <Text style={{color:'white'}}>选择“在浏览器打开”</Text>
                      </View>
                    </View> 
               
                  </View>
                  <View style={{display:'flex',flex:1,flexDirection:'column'}}>
              
                  <View style={{width:'100%',marginTop:120,textAlign:'center'}}>
                     <img alt='' src={imgLogo192} style={{width:192/2,height:192/2,borderRadius:16}}></img> 
                    </View>
                  {/* <View style={{width:'100%',marginTop:10,textAlign:'center'}}>
                     <img alt='' src={imgLogo} style={{width:492/2,height:163/2}}></img> 
                    </View> */}
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <WhiteSpace/>
                  
                    <View style={{textAlign:'center'}}>
                      <Text style={{color:mianColor,fontWeight:'bold',fontSize:16}}>
                       微信/QQ内无法下载应用
                    </Text>
                    </View>              
                  </View>                 
            </View>
            :
            <>
            <View style={{textAlign:'center',marginTop:30}}>
                <img alt='' src={imgLogo} style={{width:501/2,height:340/2}}></img> 

            </View>
            
            <View style={{width:'100%',marginTop:30,textAlign:'center'}}>
                <img alt='' src={imgBorder} style={{width:314/2,height:314/2,textAlign:'center'}}></img>
            </View>
            <View style={{width:'100%',marginTop:-149,textAlign:'center'}}>
                <img alt='' src={imgCode} style={{width:280/2,height:280/2,alignItems:'center'}}></img>
            </View>
            <View style={{textAlign:'center',marginTop:30}}>
            <Text style={{fontSize:18,color:'#333',fontWeight:300}}>扫码下载</Text>
            </View>
            <WingBlank style={{justifyItems:'center',alignItems:'center',marginTop:30}}>
      
            <WingBlank>
            
                <button  onClick={()=>{
                   window.location.href = iosUrl
                }} style={{borderRadius:32,height:40,width:'100%',backgroundColor:mianColor,border:'none'}}>
                        {/* <img alt='' src={imgGoogle} style={{width:23,height:23}}></img> */}
                        <Text style={{fontSize:16,color:'#fff'}}>iPhone苹果手机下载</Text>
                </button>

              <WhiteSpace/>
              <WhiteSpace/>

                  <button onClick={()=>{
                   window.location.href = officalUrl
                 }}    style={{borderRadius:32,height:40,width:'100%',backgroundColor:'#fff',border:'none'}}>
                          {/* <img alt='' src={imgGoogle} style={{width:23,height:23}}></img> */}
                          <Text style={{fontSize:16,color:mianColor}}>内地用户android安卓手机下载</Text>
                  </button>

              <WhiteSpace />
         
              <WhiteSpace/>
                  
            {/* <button onClick={()=>{
                   window.location.href = googleUrl
            }} style={{borderRadius:32,height:40,width:'100%',backgroundColor:mianColor,border:'none'}}>
     
                          <Text style={{fontSize:16,color:'#fff'}}>香港用户android安卓手机下载</Text>
                  </button> */}
            
            </WingBlank>
              <WhiteSpace/>
              <WhiteSpace/>
              <WhiteSpace/>
              <Flex justify='around'>
              <View onClick={()=>{
              
                 if(copy(window.location.href)){
                    Toast.show('复制成功')
                 }
                 else{
                  Toast.show('复制失败')
                 }
              }} style={{textAlign:'center',height:80}}>
                 <Text style={{color:mianColor}}>复制页面地址</Text>
              </View>
              <View onClick={()=>{
                window.location.href = customerUrl
              }} style={{textAlign:'center',height:80}}>
                 <Text style={{color:mianColor}}>联系客服</Text>
              </View>
              </Flex>
            </WingBlank>
            </>
            }
        </View>);
    }

    export default Download