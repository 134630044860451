

import React from 'react';
import './agreements.css';
export default class Agreements extends React.Component
{
  render()
  {
    return (
      <div className="App">
      <p></p>
      <div className='time'>更新日期：【2021】年【11】月【11】日</div>
      <div className='time'>生效日期：【2022】年【1】月【1】日</div>
      <p className='content'>尊敬的用户，欢迎使用由川仁（武汉）科技有限公司（下列简称为“川仁科技”或“《钓鱼基地》”）提供的服务。
      <span className='underline'>在使用前请您阅读如下服务协议，使用本应用即表示您同意接受本协议，本协议产生法律效力，特别涉及免除或者限制川仁科技责任的条款，请仔细阅读。
      </span>如有任何问题，可向川仁科技咨询。
      </p>
      <div>
  
    <div className='content'>
  
      <div className='title'>一、服务条款的确认和接受</div>
      <div>通过访问或使用本应用，表示用户同意接受本协议的所有条件和条款。</div>
      <p></p>
      <div className='title'>二、服务条款的变更和修改</div>
      <div>川仁科技有权在必要时修改服务条款，服务条款一旦发生变更，将会在重要页面上提示修改内容。如果不同意所改动的内容，用户可以放弃获得的本应用信息服务。如果用户继续享用本应用的信息服务，则视为接受服务条款的变更。本应用保留随时修改或中断服务而不需要通知用户的权利。本应用行使修改或中断服务的权利，不需对用户或第三方负责。</div>
      <p></p>
  <div className='title'>三、用户行为</div>
  <div>1、 用户账号、密码和安全</div>
  <div>用户一旦注册成功，便成为《钓鱼基地》的合法用户，将得到一个密码和帐号。同时，此账号密码可登录《钓鱼基地》的所有网页和APP。因此用户应采取合理措施维护其密码和帐号的安全。用户对利用该密码和帐号所进行的一切活动负全部责任；由该等活动所导致的任何损失或损害由用户承担，川仁科技不承担任何责任。 用户的密码和帐号遭到未授权的使用或发生其他任何安全问题，用户可以立即通知川仁科技，并且用户在每次连线结束，应结束帐号使用，否则用户可能得不到川仁科技的安全保护。对于用户长时间未使用的帐号，川仁科技有权予以关闭并注销其内容。 </div>
  <div>2、 账号注册时的禁止行为</div>
  <div className='bold'>（1）请勿以党和国家领导人或其他社会名人的真实姓名、字号、艺名、笔名注册；</div>
  <div className='bold'>（2）冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关的名称；</div>
  <div className='bold'>（3）请勿注册和其他网友之名相近、相仿的名字；</div>
  <div className='bold'>（4）请勿注册不文明、不健康名字，或包含歧视、侮辱、猥亵类词语的名字；</div>
  <div className='bold'>（5）请勿注册易产生歧义、引起他人误解的名字；</div>
  <div>3、用户在本应用上不得发布下列违法信息和照片：</div>
  
  <div className='bold'>（1）反对宪法所确定的基本原则的；</div>
  <div className='bold'>（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</div>
  <div className='bold'>（3）损害国家荣誉和利益的；</div>
  <div className='bold'>（4）煽动民族仇恨、民族歧视，破坏民族团结的；</div>
  <div className='bold'>（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
  <div className='bold'>（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
  <div className='bold'>（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</div>
  <div className='bold'>（8）侮辱或者诽谤他人，侵害他人合法权益的；</div>
  <div className='bold'>（9）含有法律、行政法规禁止的其他内容的；</div>
  <div className='bold'>（10）禁止骚扰、毁谤、威胁、仿冒网站其他用户；</div>
  <div className='bold'>（11）严禁煽动非法集会、结社、游行、示威、聚众扰乱社会秩序；</div>
  <div className='bold'>（12）严禁发布可能会妨害第三方权益的文件或者信息，例如（包括但不限于）：病毒代码、黑客程序、软件破解注册信息。</div>
  <div className='bold'>（13）禁止上传他人作品。其中包括你从互联网上下载、截图或收集的他人的作品；</div>
  <div className='bold'>（14）禁止上传广告、横幅、标志等网络图片；</div>
  <p></p>
  <div className='title'>四、上传或发布的内容</div>
  <div>用户上传的内容是指用户在《钓鱼基地》上传或发布的视频或其它任何形式的内容包括文字、图片、音频等。除非川仁科技收到相关通知，否则川仁科技将用户视为其在本应用上传或发布的内容的版权拥有人。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律及连带责任。川仁科技不承担任何法律及连带责任。
  对于经由本应用而传送的内容，川仁科技不保证前述其合法性、正当性、准确性、完整性或品质。用户在使用本应用时，有可能会接触到令人不快、不适当或令人厌恶的内容。<span className='underline'>在任何情况下，川仁科技均不对任何内容承担任何责任，包括但不限于任何内容发生任何错误或纰漏以及衍生的任何损失或损害。川仁科技有权（但无义务）自行拒绝或删除经由本应用提供的任何内容。</span>
  <div>个人或单位如认为川仁科技存在侵犯自身合法权益的内容，应准备好具有法律效应的证明材料，及时与川仁科技取得联系，以便川仁科技迅速作出处理。</div>
  </div>
  </div>
  </div>
      </div>
    );
}
}
