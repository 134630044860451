import React from 'react'
import {useParams} from 'react-router'
import {Base64} from 'js-base64'

const isURL = (str)=>{
    return !!str.match(/(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g);
}
const isNull=(str)=>{
    if(str === undefined)
    {
        return false
    }
    if(str === '' || str.trim()==='')
    {
        return false
    }
    return true
}
export default ()=>{
    const param = useParams()
    const baseUrl  = param.url  
    const notNull = isNull(baseUrl)
    let url = ''
    let isUrl = false
    if(notNull)
    {
        url = Base64.decode(baseUrl)
        isUrl = isURL(url)
    }
   
   return <div>
        {notNull&&isUrl? <iframe title='navigation' src={`${url}`} width='100%' height='1080'></iframe>:<div>预览失败,文件地址错误</div>}
    </div>
}