import React from 'react';
import {Router, Route,Switch} from 'react-router-dom'
import Agreements from './pages/agreements'
import DAgreements from './pages/dance/agreements'
import DPrivacy from './pages/dance/privacy'
import Privacy from './pages/privacy'
import Download from './pages/download'
import Main from './pages/main'
import Share from './pages/share'
import Preview from './pages/preview'
import {createBrowserHistory} from "history"
const browserHistory = createBrowserHistory();
export default class App extends React.Component
{
  render()
  {
    return  <Router history = {browserHistory}>
              <Switch>
                <Route exact path="/" component={()=><Main/>}/>
                <Route exact path="/privacy/" component={Privacy}/>
                <Route  path="/agreements/" component={Agreements}/>
                <Route  path="/dance/agreements/" component={DAgreements}/>
                <Route path="/dance/privacy/" component={DPrivacy}/>
                <Route  path="/download/:idcode?/" component={Download}/>
                <Route  path="/share/:idcode?/" component={Share}/>
                <Route exact path="/jmlink/startApp/" component={Privacy}/>
                <Route  path="/preview/:url?/" component={Preview}/>
                <Route  path="/:page?/" component={()=><Main/>}/>      
                </Switch>    
          </Router> 
    
  }
}