import React, { useEffect } from 'react';
import './privacy.css';
export default props=>
{
    useEffect(()=>{
        document.title = '隐私条款'
    },[])
    return (
      <div className="App">
      <p></p>
      <div className='time'>更新日期：【2021】年【11】月【11】日</div>
      <div className='time'>生效日期：【2022】年【1】月【1】日</div>
      <p className='content'>尊敬的用户，欢迎使用由川仁（武汉）科技有限公司（下列简称为“川仁科技”或“民艺广场舞”）提供的服务。
      <span className='underline'>在使用前请您阅读如下服务协议，使用本应用即表示您同意接受本协议，本协议产生法律效力，特别涉及免除或者限制川仁科技责任的条款，请仔细阅读。
      </span>如有任何问题，可向川仁科技咨询。
      </p>
      <div>
        <div className='content1'>
      <div className='title'>一、用户信息公开情况说明</div>
      <div>尊重用户个人隐私是川仁科技的一项基本政策。所以，川仁科技不会在未经合法用户授权时公开、编辑或透露其注册资料及保存在本应用中的非公开内容，除非有下列情况：</div>
      <div>1、有关法律规定或川仁科技合法服务程序规定；</div>
      <div>2、在紧急情况下，为维护用户及公众的权益；</div>
      <div>3、为维护川仁科技的商标权、专利权及其他任何合法权益；</div>
      <div>4、其他需要公开、编辑或透露个人信息的情况；
      <div>在以下（包括但不限于）几种情况下，川仁科技有权使用用户的个人信息：
      <div className='bold'>（1）在进行促销或抽奖时，川仁科技可能会与赞助商共享用户的个人信息，在这些情况下川仁科技会在发送用户信息之前进行提示，并且用户可以通过不参与来终止传送过程；</div>
      <div className='bold'>（2）川仁科技可以将用户信息与第三方数据匹配；</div>
      <div className='bold'>（3）川仁科技会通过透露合计用户统计数据，向未来的合作伙伴、广告商及其他第三方以及为了其他合法目的而描述川仁科技的服务；</div>
      </div>
      </div>
      <p></p>
      <div className='title'>二、隐私权政策适用范围</div>
      <div>（1）用户在登录本应用服务器时留下的个人身份信息；</div>
      <div>（2）用户通过本应用服务器与其他用户或非用户之间传送的各种资讯；</div>
      <div>（3）本应用与商业伙伴共享的其他用户或非用户的各种信息；</div>
      <p></p>
      <div className='title'>三、资讯公开与共享</div>
      川仁科技不会将用户的个人信息和资讯故意透露、出租或出售给任何第三方。但以下情况除外：
      <div className='bold'>（1）用户本人同意与第三方共享信息和资讯;</div>
      <div className='bold'>（2）只有透露用户的个人信息和资讯，才能提供用户所要求的某种产品和服务;</div>
      <div className='bold'>（3）应代表本应用提供产品或服务的主体的要求提供（除非我们另行通知，否则该等主体无权将相关用户个人信息和资讯用于提供产品和服务之外的其他用途）：根据法律法规或行政命令的要求提供;因外部审计需要而提供;用户违反了本应用服务条款或任何其他产品及服务的使用规定;经本站评估，用户的帐户存在风险，需要加以保护。</div>
      <p></p>
      <div className='title'>四、为您提供更个性化的服务</div>
      <div>（1）基于位置信息的个性化推荐功能：我们会收集您的位置信息（我们仅收集您当时所处的地理位置，但不会将您各时段的位置信息进行结合以判断您的行踪轨迹）来判断您所处的地点。如售卖，会自动为您推荐您所在区域的建材售卖信息。</div> 
      <div>（2）基于摄像头（相机）的服务功能：您可以使用这个功能完成视频拍摄、拍照、推广信息的编辑发布，或通过该功能与其他用户进行非实时的互动。</div>
      <div>（3）基于图片上传的功能：您可以用您的照片来实现您想要发布的推广信息的编辑发布。</div>
      <div>（4）基于语音技术的附加功能：您可以直接使用麦克风来和其他用户进行非实时的互动。</div>
      <div>（5）定向推送功能：我们会基于收集的信息，对您的偏好、习惯、位置作特征分析和用户画像，以便为您提供更适合的定制化服务，例如向您展现或推荐相关程度更高（而非普遍推送）的订阅信息、信息流或者广告/推广信息结果。为此，我们需要收集的信息包括您的设备信息、日志信息、浏览记录。</div>
      <div>上述附加功能可能需要您在您的设备中向我们开启您的地理位置（位置信息）、相机（摄像头）、相册（图片库）、麦克风，以实现这些功能所涉及的信息的收集和使用。我们将在您首次下载APP或者具体使用到上述所列功能时逐一以弹窗的形式提前向您提示，您可以根据您的需要进行选择。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</div>
      <p></p>

      <div className='title'>五、Cookies、日志档案和webbeacon</div>
      <div>通过使用cookies，本应用向用户提供简单易行并富个性化的网络体验。cookies能帮助我们确定用户连接的页面和内容，并将该等信息储存。我们使用自己的cookies和webbeacon，用于以下用途：</div>
      <div>（1）记住用户身份。例如：cookies和webbeacon有助于我们辨认用户作为我们的注册用户的身份，或保存用户向我们提供有关用户的喜好或其他信息；</div> 
      <div>（2）分析用户使用我们服务的情况。我们可利用cookies和webbeacon来了解用户使用我们的服务进行什么活动、或哪些网页或服务最受欢迎； 我们为上述目的使用cookies和webbeacon的同时，可能将通过cookies和webbeacon收集的非个人身份信息汇总提供给广告商和其他伙伴，用于分析您和其他用户如何使用我们的服务并用于广告服务。用户可以通过浏览器或用户选择机制拒绝或管理cookies或webbeacon。但请用户注意，如果用户停用cookies或webbeacon，我们有可能无法为您提供最佳的服务体验，某些服务也可能无法正常使用。 </div>
      <p></p>
      <div className='title'>六、账户删除申请</div>
      <div>（1）用户有权在任何时候编辑用户在川仁科技的帐户信息和资讯，用户也可以要求删除个人帐户。</div>
      <div>（2）用户可以通过《民艺广场舞》注销账户。在满足注销条件的情况下，用户通过验证并确认注销账户即可立即注销账户。注销方式：APP首页下方“我的”-右上角“设置”，选择“账号与安全”-“注销账号”，即可按照要求注销账户。</div>
      <div>（3）您注销账户后，我们将停止为您提供产品与/或服务，并依据您的要求，除法律法规另有规定外，我们将删除您的个人信息。</div>
      <div className='title'>七、除开以上隐私协议，以下开始列明第三方平台或SDK使用到的共享信息</div>
      <div>（1）极光魔链SDK（JMlink SDK）</div>
      <div><span>获取的权限或数据：获取并上传用户的已安装应用信息（Installed Application information）。</span></div>
      <div><span>使用目的：获取下载页面上传的邀请码，用于APP中的邀请功能。</span></div>
      <div><span style={{wordWrap:"break-word"}}>极光隐私协议：<a className="url-a" href="https://www.jiguang.cn/license/privacy">https://www.jiguang.cn/license/privacy</a></span></div>
     
        <div>（2）MobSDK服务</div>
        <span>我们使用了第三方（上海游昆信息技术有限公司，以下称“MobTech”）MobTech SecVerifySDK服务为您提供一键登录功能。为了顺利实现该功能，您需要授权MobTechSDK提供对应的服务;在您授权后，MobTech将收集您相关的个人信息。关于MobTech所收集的信息种类、用途、个人信息保护的规则及退出机制等，详见MobTech官网（<a className="url-a" href="https://www.mob.com">www.mob.com</a>）上的隐私政策 <span>（<a className="url-a" href="https://www.mob.com/about/policy">www.mob.com/about/policy</a>）</span>条款</span>
      </div>
      </div>
      </div>
    );

}
