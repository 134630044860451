
import React from 'react';
import 'antd-mobile/dist/antd-mobile.css';
import "./index.css"
import {useParams} from 'react-router'
import imgLogo192 from "../img/logo192.png"
import imgLogo from "../img/logo.png"
import { WhiteSpace, View,Text} from 'antd-mobile';
const isWeiXin=()=>
{

  let ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i)?.indexOf("micromessenger")>-1 || ua.match(/WeiBo/i)?.indexOf('weibo')>-1) {
    return true;
  } else if (ua.indexOf('mobile mqqbrowser') > -1 || ua.indexOf('webp') > -1) {
    return true;
  } else if (ua.indexOf('iphone') > -1 || ua.indexOf('mac') > -1) {
    if (ua.indexOf('qq') > -1) {
      return true;
    }
  }
  return false;
}
const Share =()=>{
        const allparams = useParams()
        const btnRef = React.useRef(null)
        const btnRef1 = React.useRef(null)
        const [isShow] = React.useState(isWeiXin())
        React.useEffect(()=>{
          new window.JMLink({
            jmlink:'https://bq8mlm.jglinks.cn/AaZN',
            button:btnRef.current,
            invtparams:{  idcode:allparams.idcode ||''}
          })

          new window.JMLink({
            jmlink:'https://bq8mlm.jglinks.cn/AaZ3',
            button:btnRef1.current,
            invtparams:{  idcode:allparams.idcode ||''}
          })
          // if(!isShow)
          // {
          //   btnRef1.current.click()
          // }
          // 
        })
  
        return (
          <View style={{flex:1,justifyContent:'center'}}>
            {isShow ? <View style={{flex:1,display:'flex',flexDirection:'column'}}>
                  <View style={{position:'absolute',right:0}}>
                    <View className='App-arrow'></View>
                    <View style={{display:'flex',marginTop:10,flexDirection:'column',alignItems:'center',justifyContent:'center',backgroundColor:'#00c7fc',flexWrap:'wrap',padding:8,borderRadius:16}}>
                      <View style={{justifyContent:'center',alignItems:'center',flexWrap:'wrap'}}>
                      <Text style={{color:'white'}}>请点击右上角</Text>
                      </View>
                      <View style={{justifyContent:'center',alignItems:'center',flexWrap:'wrap'}}>
                      <Text style={{color:'white'}}>选择“在浏览器打开”</Text>
                      </View>
                    </View> 
               
                  </View>
                  <View style={{display:'flex',flex:1,flexDirection:'column'}}>
                  
                  <View style={{width:'100%',marginTop:120,textAlign:'center'}}>
                     <img alt='' src={imgLogo192} style={{width:192/1.4,height:192/1.4,borderRadius:16}}></img> 
                    </View>
                  <View style={{width:'100%',marginTop:10,textAlign:'center'}}>
                     <img alt='' src={imgLogo} style={{width:492/2,height:163/2}}></img> 
                    </View>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <View style={{textAlign:'center'}}>
                      <Text style={{color:'#00c7fc',fontWeight:'bold',fontSize:16}}>
                       微信/QQ内无法下载应用
                    </Text>
                    </View>
                  </View>
            </View>:
                <>
                  <View style={{width:'100%',marginTop:80,textAlign:'center' ,flex:1}}>
                     <img alt='' src={imgLogo192} style={{width:192/1.4,height:192/1.4,borderRadius:16}}></img> 
                    </View>
                    <View style={{width:'100%',marginTop:10,textAlign:'center'}}>
                     <img alt='' src={imgLogo} style={{width:492/2,height:163/2}}></img> 
                    </View>
                    <View style={{textAlign:'center',marginTop:20}}>
                      <Text style={{fontSize:16}}>自动跳转到下载...</Text>
                    </View>
                    <View style={{textAlign:'center',position:'absolute',bottom:60,width:'100%'}}>
                      <button ref={btnRef} style={{borderRadius:32,marginRight:'10%',height:40,marginLeft:'10%',width:'80%',backgroundColor:'#08f',border:'none'}}>
                        <Text style={{fontSize:16,color:'white'}}>前往下载</Text>
                      </button>
                      <button ref={btnRef1} style={{borderRadius:32,marginRight:'10%',height:40,marginLeft:'10%',width:'80%',backgroundColor:'#08f',border:'none'}}>
                        <Text style={{fontSize:16,color:'white'}}>前往下载1</Text>
                      </button>
                    </View>
                </>
        }
          </View>)
    }

    export default Share