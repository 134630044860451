import React from 'react';
import 'element-theme-default';
import  {Layout, Button} from 'element-react';
import imgCode from "./img/code.png"
export default class Product extends React.Component
{
  render()
  {
    return (<div>
              <div style={{width:'100%',textAlign:'center'}}>
                <img src={require('../img/banner_2.png')} alt="" style={{width:'100%'}}></img></div>
              <div style={{width:'80%',margin:'0 auto'}}>
                <div style={{textAlign:'center',height:120,marginTop:60}}><img src={require('../img/introduction_title.png')} alt="" style={{height:66,width:637}}></img></div>
                <Layout.Row>
                <Layout.Col span="12"> <div style={{height:314,width:540,textAlign:'center'}}><img src={require('../img/introduction.png')} alt="" style={{height:314,width:540}}></img></div></Layout.Col>
                <Layout.Col span="12"><p style={{height:120,margin:40,color:'gray'}}>《钓鱼基地》是一款提供找钓点服务的APP。</p></Layout.Col>
                </Layout.Row>
                <div style={{height:60}}></div>
            </div>
              <div style={{backgroundColor:'#f7f7f7'}}>
                  <div style={{width:'80%',margin:'0 auto'}}>
                    <div style={{backgroundColor:'#f7f7f7',height:60}}></div>
                    <div style={{textAlign:'center',height:120}}><img src={require('../img/download_title.png')} alt="" style={{height:66,width:551}}></img></div>
                    <Layout.Row>
                        <Layout.Col span="12">

                        {/* <div style={{width:'100%',textAlign:'center'}}>
                            <img alt='' src={imgCode} style={{width:280/2,height:280/2,alignItems:'center'}}></img>
                        </div> */}
                        <div style={{textAlign:'center',marginTop:30}}>

                     
                        <p style={{height:120,margin:40,color:'gray'}}> 产品暂未开放下载，敬请期待!</p>
                        </div>

                        </Layout.Col>
                        <Layout.Col span="12"> <div style={{height:314,width:540,textAlign:'center'}}><img src={require('../img/download.png')} alt="" style={{height:314,width:540}}></img></div></Layout.Col>
                    </Layout.Row>
                  </div>
                    <div style={{backgroundColor:'#f7f7f7',height:60}}></div>
                  </div>
          </div>)
  }
}