import React from 'react'


export default class Recruit extends React.Component
{
  render()
  {
    return (<div>
              <div style={{width:'100%',textAlign:'center'}}><img src={require('../img/banner_3.png')} alt="" style={{width:'100%'}}></img></div>
              <div style={{width:'80%',margin:'0 auto'}}>
              {/* <div style={{textAlign:'center',height:120,marginTop:60}}><img src={require('../img/ui_title.png')} alt="" style={{height:66,width:249}}></img></div>
            
              <div style={{textAlign:'center',height:120,marginTop:60}}><img src={require('../img/cehua_title.png')} alt="" style={{height:66,width:511}}></img></div>
              <div style={{textAlign:'center',height:120,marginTop:60}}><img src={require('../img/cpp_title.png')} alt="" style={{height:66,width:759}}></img></div> */}
              <div style={{height:60}}></div>
                  <h3 style={{color:'rgb(0,66,106)' }}>岗位名称</h3>
                  <span>前端开发工程师(React-Native)</span>
            
                  <h3 style={{color:'rgb(0,66,106)'}}>岗位职责</h3>
                  <ol>
                    <li>
                    使用React-Native进行公司移动应用的研发，并完成ios/android客户端集成。
                    </li>
                    <li>
                    持续改进移动App性能，安全性，稳定性和可扩展性。
                    </li>
                    <li>
                    根据产品需求和项目计划按时提交完成架构和模块设计编码等工作。
                    </li>
                    <li>
                    研究和探索创新的开发思路。
                    </li>
                  </ol>
                  <h3 style={{color:'rgb(0,66,106)'}}>任职要求</h3>
                  <ol>
                    <li>
                      熟练掌握React-Native的基本原理及生命周期，并有完整的项目开发经验。
                    </li>
                    <li>
                      熟悉React-Native与原生混编模式，有一定的android/ios原生开发经验者优先。
                    </li>
                    <li>
                      具有React-Native在android和ios端app适,性能，安全方面的经验值优先。
                    </li>
                    <li>
                      精通JavaScript/HTML5/CSS3,熟悉react,redux,vue等web前端技术。
                    </li>
                    <li>
                      熟悉模块化，组件化，前端工程化，熟悉webpack,grunt等前端构建工具。
                    </li>
                  </ol>
                  <hr></hr>
                  <h3 style={{color:'rgb(0,66,106)'}}>岗位名称</h3>
                  <span>后端开发工程师(Java)</span>
            
                  <h3 style={{color:'rgb(0,66,106)'}}>岗位职责</h3>
                  <ol>
                  <li>
                    负责研发中心产品的设计和开发工作。
                    </li>
                    <li>
                    按照设计方案按时、独立、高质量完成交付。
                    </li>
                    <li>
                    能快速分析解决复杂的技术问题、积极了解业界发展、互联网相关新技术及趋势。
                    </li>
                  </ol>
                  <h3 style={{color:'rgb(0,66,106)'}}>任职要求</h3>
                  <ul>
                    <li>
                      3年以上编程工作经验，精通Java开发，熟练掌握多线程，熟悉常用设计模式。
                    </li>
                    <li>
                      能够熟练使用Spring-boot、Spring-data、MyBatis等常用组件开发，了解JavaScript、JQuery、Ajax的应用。
                    </li>
                    <li>
                      熟悉Linux操作系统常用命令，能够对系统进行日常维护，熟悉MySQL等数据库和redis的基本使用。
                    </li>
                    <li>
                      对技术有追求，有良好的沟通协调能力和强有力的执行能力，具备较强的分析问题和解决问题的能力。
                    </li>
                  </ul>
          

                  <h3 style={{color:'rgb(0,66,106)'}}>工作地点</h3>
                  <span>武汉市东湖高新区 </span>
                  <h3 style={{color:'rgb(0,66,106)'}}>简历投递</h3>
                  <span>hr@tcctop.com </span>
                </div>
                <div style={{height:60}}></div>
        </div>)
  }
}