import React from 'react';
import Backgroud from "../img/foot.png"
export default class Foot extends React.Component
{
  render()
  {
    return (<>
            <div style={{backgroundImage:`url(${Backgroud})`, height:200}}>
              <div style={{height:60}}></div>
                <p style={{textAlign:'center',width:'100%',height:30,alignItems:'center',fontSize:18,color:'white'}}>
                  <a style={{color:'white',textDecorationLine:'underline'}} target='_blank' href='https://beian.miit.gov.cn'>鄂ICP备2022005072号</a>
                </p>
                <p style={{textAlign:'center',width:'100%',height:30,alignItems:'center',fontSize:18,color:'white'}}>《川仁（武汉）科技有限公司》版权所有</p>
            </div>
            </>)
  }
}